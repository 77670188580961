import React, {ReactNode, useState} from 'react';
import stylesLess from './NavMenu.module.less';


import clsx from "clsx";

type Props = {
    className?: string,
    label?: string | ReactNode,
    component?: any,
};

export const NavMenu: React.FC<React.PropsWithChildren & Props> = ({children, label, className, component}) => {
    // console.log(typeof component);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const menuClose = () => {
        // e.preventDefault();
        setMenuIsOpen(!menuIsOpen);
    }
    // const handleHeaderEnter = () => {
    //     // e.preventDefault();
    //     setMenuIsOpen(true);
    // };

    const handleHeaderLeave = () => {
        // e.preventDefault();
        setMenuIsOpen(false);
        // console.log('handleHeaderLeave');
    }

    // const Hello:React.FC<any> = (props:any) => <div>HELLO</div>
    // const Component = component ? component : Link;

    return (
        // <div className={clsx(stylesLess.header, className)}>
        <div
            onClick={menuClose}
            // onMouseEnter={handleHeaderEnter}
            onMouseLeave={handleHeaderLeave}
            className={clsx(stylesLess.header,)}
        >
            {/*{component && component({})}*/}
            {/*<div className={clsx(className,)}>*/}
                {/*<div className={stylesHeader.navBut}>*/}
                {/*<div className={stylesLess.label}>*/}
                {label}
            {/*</div>*/}
            <div
                className={clsx(menuIsOpen && stylesLess.menuIsOpen, stylesLess.content)}>{children}
            </div>
        </div>

        // </div>
    );
};